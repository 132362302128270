// src/components/FolderModal.js

import React, { useState, useEffect, useRef } from 'react';

function FolderModal({ onClose, onCreate }) {
    const [name, setName] = useState('');
    const inputRef = useRef(null); // Create a ref for the input field

    // Focus the input field when the modal opens
    useEffect(() => {
        inputRef.current.focus(); // Automatically focus the input field
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreate({ name });
        setName('');
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Create New Folder</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Folder Name</label>
                    <input
                        type="text"
                        value={name}
                        ref={inputRef} // Attach the ref to the input field
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 focus:border-blue-500 focus:outline-none shadow-sm p-1" // Added height and focus styling
                        required
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-500 text-white px-3 py-1 border border-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-3 py-1 ml-2 border border-green-500"
                    >
                        Create
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FolderModal;
