import React from 'react';

function Breadcrumb({ folderPath, onNavigate }) {
    return (
        <div className="flex items-center text-sm text-white">
            {/* Display "My Dashboard" as the initial breadcrumb item */}
            {folderPath.length > 0 && (
                <>
                    <button
                        onClick={() => onNavigate(null)}  // Navigate back to the top-level folders
                        className="hover:underline focus:outline-none text-gray-300 px-4"
                    >
                        My Dashboard
                    </button>
                    {folderPath.length > 1 && <span className="mx-2 text-gray-400">/</span>}
                </>
            )}
            {/* Dynamically display the folder path */}
            {folderPath.slice(1).map((folder, index) => (
                <React.Fragment key={folder.id || index}>
                    <button
                        onClick={() => onNavigate(folder.id)}
                        className="hover:underline focus:outline-none text-gray-300 px-4"
                    >
                        {folder.name}
                    </button>
                    {index < folderPath.length - 2 && (
                        <span className="mx-2 text-gray-400">/</span>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Breadcrumb;
