import React, {useState, useRef, useContext} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { API_BASE_URL } from '../config';
import { AppContext } from '../AppContext';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';

function VideoUpload() {
    const { folderId } = useContext(AppContext);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);
    const fileInputRef = useRef(null); // Use ref to control file input
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [isUploaded, setIsUploaded] = useState(false); // Track if upload is completed

    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedVideo(file);

            // Create a URL for the selected video to preview it
            const videoURL = URL.createObjectURL(file);
            setPreviewVideo(videoURL);
            setIsUploaded(false); // Reset upload state
        }
    };

    const handleUpload = async () => {
        if (!selectedVideo) {
            alert('No video selected.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedVideo);
        formData.append('folder', folderId);

        try {
            setIsLoading(true);

            const response = await axiosInstance.post(
                `${API_BASE_URL}/videos/upload/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 201) {
                // Successfully uploaded video
                setSelectedVideo(null);
                setPreviewVideo(null);
                setIsUploaded(true); // Mark as uploaded
                fileInputRef.current.value = ''; // Clear file input

                // Redirect based on folderId
                if (folderId === null) {
                    navigate('/home');
                } else {
                    navigate(`/folder/${folderId}`);
                }
            } else {
                alert(`Failed to upload video: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error uploading video:', error);
            alert('Error uploading video.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChooseAnotherVideo = () => {
        // Reset the state
        setSelectedVideo(null);
        setPreviewVideo(null);
        fileInputRef.current.value = ''; // Clear the file input value
        setIsUploaded(false); // Reset upload state
    };
    return (
        <div>
            <Header />
            <main>
                <div className="h-full px-4 py-6 flex justify-center items-center">
                    {/* Video upload container */}
                    <div className="border-dashed border-2 border-gray-400 rounded-lg w-2/3 p-10 text-center">
                        {previewVideo ? (
                            <div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
                                <video className="mb-4 w-auto h-[480px] max-w-full" controls>
                                    <source src={previewVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ) : (
                            <>
                                <p className="text-gray-500 text-xl mb-4">Drag and drop your videos here or click to select one</p>
                                <p className="text-gray-500 mb-6">Only MP4 and MOV files are accepted</p>
                            </>
                        )}
                        <input
                            type="file"
                            accept="video/mp4, video/mov"
                            id="videoUpload"
                            className="hidden"
                            onChange={handleFileUpload}
                            ref={fileInputRef} // Attach ref to the file input
                        />

                        <div className="flex flex-col items-center">
                            <button
                                className={`bg-[#4caf50] text-white px-4 py-2 rounded mb-2 ${isLoading ? 'cursor-not-allowed' : ''}`}
                                onClick={selectedVideo ? handleUpload : () => fileInputRef.current.click()}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Uploading...' : selectedVideo ? 'Upload Video' : 'Choose a video'}
                            </button>

                            {selectedVideo && !isLoading && (
                                <button
                                    className="text-blue-500 underline"
                                    onClick={handleChooseAnotherVideo}
                                >
                                    Choose Another Video
                                </button>
                            )}

                            {/* Green loading icon during upload */}
                            {isLoading && (
                                <div className="flex items-center mt-4">
                                    <div className="w-8 h-8 rounded-full border-4 border-[#4caf50] border-t-transparent animate-spin"></div>
                                </div>
                            )}

                            {isUploaded && !isLoading && (
                                <div className="flex items-center mt-4">
                                    <div className="w-8 h-8 rounded-full border-4 border-[#4caf50]"></div>
                                    <span className="ml-2 text-[#4caf50]">Upload Complete!</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className="ml-10">
                        <h2 className="text-white mb-4">For an optimal analysis, please confirm your video meets these requirements:</h2>
                        <div className="flex mb-4">
                            <button className="bg-orange-500 text-white px-4 py-2 rounded-l-full">min/km</button>
                            <button className="bg-gray-700 text-white px-4 py-2">min/mil</button>
                            <button className="bg-gray-700 text-white px-4 py-2">km/h</button>
                            <button className="bg-gray-700 text-white px-4 py-2 rounded-r-full">mil/h</button>
                        </div>
                        <label className="block text-white mb-2">Select your running pace</label>
                        <select className="w-full px-3 py-2 bg-gray-800 text-white rounded border border-gray-700">
                            <option>5:00 min/km</option>
                            <option>6:00 min/km</option>
                            <option>7:00 min/km</option>
                        </select>
                        <ul className="mt-4 space-y-2">
                            <li className="text-[#4caf50]">✔ The camera is stable, not following the athlete</li>
                            <li className="text-[#4caf50]">✔ The runner is side view and visible from head to toe</li>
                            <li className="text-[#4caf50]">✔ There is only one person on the video</li>
                            <li className="text-[#4caf50]">✔ Video in landscape and no slow motion</li>
                            <li className="text-[#4caf50]">✔ No treadmill, the runner is in natural environment</li>
                        </ul>
                        <div className="mt-6">
                            <button className="bg-gray-700 text-gray-400 px-4 py-2 rounded-full cursor-not-allowed">
                                My video is good 👍
                            </button>
                            <a href="#" className="text-orange-500 block mt-4">My video is good but I don’t know the pace</a>
                        </div>
                    </div> */}
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default VideoUpload;
