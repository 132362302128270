import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import axios from 'axios';


function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);  // Show loading spinner

    // Check if passwords match
    if (password !== confirmPassword) {
        setError('Passwords do not match');
        setIsLoading(false);  // Hide loading spinner
        return;
    }

    try {
        const response = await axios.post(`${BASE_URL}/api/auth/reset-password/${token}/`,
        { password },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            // Navigate to login page on successful password reset
            navigate('/login');
        } else {
            setError(response.data.error || 'Password reset failed');
        }
    } catch (error) {
        if (error.response) {
            // The request was made, and the server responded with a status code that falls out of the range of 2xx
            setError(error.response.data.error || 'Password reset failed');
        } else {
            // Something happened in setting up the request that triggered an Error
            setError('An error occurred. Please try again later.');
            console.error('Error resetting password:', error.message);
        }
    } finally {
        setIsLoading(false);  // Hide loading spinner in all cases
    }
};



  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
  <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
    <h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Reset Password</h2>
    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoFocus
          className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] text-[#212121]"
        />
      </div>
      <div className="mb-6">
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] text-[#212121]"
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className={`bg-[#4caf50] text-white font-bold py-2 px-4 rounded focus:outline-none w-full focus:shadow-outline ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Reset Password'}
        </button>
      </div>
    </form>
  </div>
</div>

  );
}

export default ResetPassword;
