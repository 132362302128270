import axios from 'axios';
import { API_BASE_URL } from './config';  // Adjust the path according to your project structure

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the tokens object from localStorage
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    const accessToken = tokens?.access;

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loops

      try {
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        const refreshToken = tokens?.refresh;

        // Request a new access token using the refresh token
        const refreshResponse = await axios.post(`${API_BASE_URL}/auth/token/refresh/`, {
          refresh: refreshToken,  // Only pass refresh token to refresh endpoint
        });

        if (refreshResponse.status === 200) {
          const newAccessToken = refreshResponse.data.access;  // Only the new access token is returned
          console.log(newAccessToken);

          // Update only the access token, keep the refresh token as is
          localStorage.setItem('tokens', JSON.stringify({
            access: newAccessToken,
            refresh: refreshToken,  // Keep the same refresh token
          }));

          // Update the access token in the original request and retry it
          axiosInstance.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          return axiosInstance(originalRequest);
        } else {
          // If refreshing token fails, handle the logout logic
          logoutUser(); // Implement your logout logic here
          return Promise.reject(error);
        }
      } catch (refreshError) {
        logoutUser(); // Implement your logout logic here
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

const logoutUser = () => {
  localStorage.removeItem('tokens');
  localStorage.removeItem('user');
  window.location.href = '/login'; // Redirect to login page or implement your routing logic
};

export default axiosInstance;
