import React from 'react';
import { Link } from 'react-router-dom';

function VerifyEmail() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Verify Your Email</h2>
        <p className="text-center text-gray-700 mb-6">
          A verification email has been sent to your email address. Please check your inbox and click on the link to verify your account.
        </p>
        <p className="text-center text-gray-500 mb-6">
          If you did not receive the email, check your spam folder or
          <Link to="#" className="text-blue-500 hover:underline"> click here </Link>
          to resend.
        </p>
        <Link to="/login" className="block text-center bg-[#4caf50] text-white py-2 rounded hover:bg-green-600 focus:outline-none">
          Go to Login
        </Link>
      </div>
    </div>
  );
}

export default VerifyEmail;
