import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import axios from 'axios';


function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/register/`, {
        username,
        email,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 201) { // Assuming a 201 User is created
        navigate('/verify-email'); // Redirect to Verify Email page
      } else {
        setErrorMessage(response.data.error || 'Registration failed');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Error registering user');
      }
      console.error('Error registering user', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };


  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
  <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-8 rounded shadow-md">
    <h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Register</h2>
    {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
    <input
      type="text"
      placeholder="Username"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] mb-4 text-[#212121]"
      required
      autoFocus
    />
    <input
      type="email"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] mb-4 text-[#212121]"
      required
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] mb-4 text-[#212121]"
      required
    />
    <button
      type="submit"
      className={`w-full flex items-center justify-center bg-[#4caf50] text-white py-2 rounded hover:bg-green-600 focus:outline-none ${
        loading ? 'cursor-not-allowed' : ''
      }`}
      disabled={loading}
    >
      {loading ? (
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      ) : (
        'Register'
      )}
    </button>
    <p className="text-center mt-4">
      Already have an account?{' '}
      <Link to="/login" className="text-blue-500 hover:underline">
        Login
      </Link>
    </p>
    <div className="text-center mt-4">
      <div className="relative mb-4">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 text-gray-500">OR</span>
        </div>
      </div>
      <button className="w-full flex items-center justify-center py-2 mb-2 border border-gray-300 rounded-md hover:bg-gray-50">
        <img
          className="h-5 w-5 mr-2"
          src={require('../static/google.jpeg')}
          alt="Google icon"
        />
        Continue with Google
      </button>
    </div>
  </form>
</div>

  );
}
export default Register;
