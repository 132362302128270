// src/pages/ForgotPasswordPage.jsx
import React, { useState } from 'react';
import { BASE_URL } from '../config';
import axios from 'axios';  // Make sure to import axios

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e) => {
  e.preventDefault();
  setLoading(true);
  setErrorMessage('');
  setMessage('');

  try {
      await axios.post(`${BASE_URL}/api/auth/forgot-password/`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    setMessage('A password reset link has been sent to your email.');

  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      setErrorMessage(error.response.data.error || 'Failed to send password reset link.');
    } else {
      // Something happened in setting up the request that triggered an Error
      setErrorMessage('Error occurred while sending reset link.');
    }
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }
  };


  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
  <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
    <h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Forgot Password</h2>
    {message && <div className="text-green-500">{message}</div>}
    {errorMessage && <div className="text-red-500">{errorMessage}</div>}
    <form onSubmit={handleForgotPassword}>
      <div className="mb-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoFocus
          placeholder="Email"
          className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] text-[#212121]"  // Updated text color and focus color
        />
      </div>
      <div className="mt-6">
        <button
          type="submit"
          className="w-full px-4 py-2 font-semibold text-white bg-[#4caf50] rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Reset Link'}
        </button>
      </div>
    </form>
    <div className="text-center">
      <a href="/login" className="text-blue-500 hover:underline">
        Back to Login
      </a>
    </div>
  </div>
</div>

  );
};

export default ForgotPassword;
