import { useNavigate, useLocation, Link } from 'react-router-dom';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from '../AppContext'; // Import context
import logo from '../static/fit-q.svg';
import { BASE_URL } from '../config';

function Header({ onCreateFolder}) {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const dropdownRef = useRef(null);
	const { logout } = useContext(AppContext);

	const toggleDropdown = () => {
		setDropdownVisible(!isDropdownVisible);
	};

    const handleNewAnalysisClick = () => {
        navigate('/video-upload'); // Navigate without folderId in URL now
    };

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownVisible(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
		  	document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);

    return (
		<header>
			<div className="flex justify-between py-2 px-5 bg-black1 text-white">
			<div className="w-1/5">
				<Link to="/home">
					<img src={`${BASE_URL}/${logo}`} className="w-24 h-auto" alt="Logo" />
				</Link>
			</div>
			<div className="w-4/5 flex justify-end items-center">
				<button
				onClick={handleNewAnalysisClick}
				className="rounded-md bg-[#4caf50] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#3e8e41] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4caf50]"
				>
				<i className="fa fa-video ml-2" /> NEW ANALYSIS
				</button>

				{(location.pathname === '/home' || location.pathname.startsWith('/folder/')) && (
				<button
					onClick={onCreateFolder}
					className="rounded-md bg-[#4caf50] px-3.5 py-2.5 ml-1 text-sm font-semibold text-white shadow-sm hover:bg-[#3e8e41] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4caf50]"
				>
					<i className="fa fa-folder-plus ml-2" /> CREATE FOLDER
				</button>
				)}

				<div className="relative" ref={dropdownRef}>
				<i
					className="fa fa-user text-white text-2xl pl-5 cursor-pointer"
					onClick={toggleDropdown}
				/>

				{isDropdownVisible && (
					<div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
					<Link
						to="#"
						className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
					>
						View Profile
					</Link>
					<button
						onClick={logout}
						className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
					>
						Logout
					</button>
					</div>
				)}
				</div>
			</div>
			</div>
			<hr />
		</header>
    );
}

export default Header;
