import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AppContext = createContext({
  user: null,
  tokens: null,
  folderId: null,
  loading: true,
  login: () => {},
  logout: () => {}
});

// Create a provider component
export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Check for saved tokens in localStorage on initial load
    const storedTokens = localStorage.getItem('tokens');
    const storedUser = localStorage.getItem('user');

    if (storedTokens) {
      setTokens(JSON.parse(storedTokens));
    }
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // Set loading to false after checking localStorage
    setLoading(false);
  }, []);

  const login = (tokens, user) => {
    setTokens(tokens);
    setUser(user);
    localStorage.setItem('tokens', JSON.stringify(tokens));
    localStorage.setItem('user', JSON.stringify(user));
  };

  const logout = () => {
    setTokens(null);
    setUser(null);
    localStorage.removeItem('tokens');
    localStorage.removeItem('user');
  };

  return (
    <AppContext.Provider value={{ user, tokens, folderId, loading, login, logout, setFolderId }}>
      {children}
    </AppContext.Provider>
  );
};
