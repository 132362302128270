import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';

function FolderList({ folders, videos, onViewFolder, setFolders, setVideos }) {
    const navigate = useNavigate();
    const defaultThumbnailIcon = 'fas fa-video';
    const isFolderEmpty = folders.length === 0 && videos.length === 0;

    const [activeVideoMenu, setActiveVideoMenu] = useState(null);  // State to manage which video menu is open
    const [activeFolderMenu, setActiveFolderMenu] = useState(null);  // State to manage which folder menu is open

    const toggleVideoMenu = (id) => {
        setActiveVideoMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id)); // Toggle video menu visibility
    };

    const toggleFolderMenu = (id) => {
        setActiveFolderMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id)); // Toggle folder menu visibility
    };

    // Function to handle menu item clicks for both videos and folders
    const handleMenuItemClick = (action, id, type, event) => {
      event.stopPropagation();  // Prevent event from bubbling up to parent elements
      console.log(`Action: ${action}, ID: ${id}, Type: ${type}`);
      if (action === 'move_to_trash') {
          // Call appropriate delete handler based on type (video or folder)
          if (type === 'video') {
              handleDeleteVideo(id);
          } else if (type === 'folder') {
              handleDeleteFolder(id);
          }
      }
      setActiveVideoMenu(null);  // Close the dropdown menu after an action
      setActiveFolderMenu(null);  // Close the dropdown menu after an action
  };

    // Function to delete a video
    const handleDeleteVideo = async (id) => {
      try {
          await axiosInstance.delete(`/videos/${id}/delete/`);

          // Remove deleted video from state
          onDeleteSuccess('video', id);
      } catch (error) {
          console.error('Error deleting video:', error);
      }
  };

  // Function to delete a folder
  const handleDeleteFolder = async (id) => {
      try {
          await axiosInstance.delete(`/folders/${id}/delete/`);  // Ensure you have the correct endpoint

          // Remove deleted folder from state
          onDeleteSuccess('folder', id);
      } catch (error) {
          console.error('Error deleting folder:', error);
      }
  };

    // Function to handle success after deleting a video or folder
    const onDeleteSuccess = (type, deletedId) => {
        if (type === 'video') {
            setVideos(prevVideos => prevVideos.filter(video => video.id !== deletedId));
        } else if (type === 'folder') {
            setFolders(prevFolders => prevFolders.filter(folder => folder.id !== deletedId));
        }
    };

    return (
      <div className="px-4 py-3">
      {isFolderEmpty ? (
          <div className="text-center text-gray-500 col-span-full w-full">
              Folder is empty
          </div>
      ) : (
          <>
              <div className="flex flex-wrap gap-4 mb-3">
                  {videos.length > 0 &&
                      videos.map((video) => (
                          <div
                              key={video.id}
                              className="relative bg-[#2D2D2D] video-item w-full sm:w-[calc(50%-1rem)] md:w-[calc(33%-1rem)] lg:w-[calc(25%-1rem)] xl:w-[calc(20%-1rem)] cursor-pointer shadow-md transition-transform transform hover:scale-105"
                              onClick={() => navigate(`/video-analysis/${video.id}`)}
                              style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
                          >
                              <div className="bg-[#2D2D2D] flex items-center justify-center h-40">
                                  {video.thumbnail ? (
                                      <img
                                          src={`${BASE_URL}/${video.thumbnail}`}
                                          alt="Video Thumbnail"
                                          className="w-full h-40 object-cover"
                                      />
                                  ) : (
                                      <i className={`${defaultThumbnailIcon} text-[#4CAF50] text-6xl`}></i>
                                  )}
                              </div>
                              <div className="p-4 bg-[#2D2D2D] shadow-md" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
                                  <p className="text-white text-sm">Uploaded by: {video.uploaded_by}</p>
                                  <p className="text-xs text-gray-400">Uploaded on: {new Date(video.created_at).toLocaleDateString()}</p>
                              </div>
                              {/* Three-dot menu icon with hover effect */}
                              <div className="absolute bottom-2 right-2">
                                  <button
                                      onClick={(e) => {
                                          e.stopPropagation(); // Prevent triggering video card click
                                          toggleVideoMenu(video.id);
                                      }}
                                      className="text-white p-2 px-3 rounded-full hover:bg-gray-700 focus:outline-none"
                                      style={{ transition: 'background-color 0.2s ease' }} // Smooth transition for hover effect
                                  >
                                      <i className="fas fa-ellipsis-v"></i>
                                  </button>
                              </div>
                              {/* Dropdown menu */}
                              {activeVideoMenu === video.id && (
                                  <div className="absolute bottom-10 right-0 bg-white text-black shadow-lg rounded">
                                      <ul className="p-2">
                                          <li className="hover:bg-gray-200 p-2 cursor-pointer text-red-500 flex justify-between">
                                              <span onClick={(e) => handleMenuItemClick('move_to_trash', video.id, 'video', e)}>Move to trash</span>
                                          </li>
                                      </ul>
                                  </div>
                              )}
                          </div>
                      ))
                  }
              </div>
              <div className="flex flex-wrap gap-4">
                  {folders.length > 0 && (
                      folders.map((folder) => (
                          <div
                              key={folder.id}
                              className="relative bg-[#2D2D2D] shadow-md w-full sm:w-[calc(50%-1rem)] md:w-[calc(33%-1rem)] lg:w-[calc(25%-1rem)] xl:w-[calc(20%-1rem)] cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                              onClick={() => onViewFolder(folder.id)}
                              style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
                          >
                              <div className="bg-[#2D2D2D] h-40 flex items-center justify-center">
                                  <i className="fas fa-folder text-[#4CAF50] text-6xl"></i>
                              </div>
                              <div className="p-4 bg-[#2D2D2D] shadow-md" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
                                  <span className="text-sm font-medium text-white block">{folder.name}</span>
                                  <span className="text-xs text-gray-400 mt-1 block">Edited {folder.updated_at || 'some time ago'}</span>
                              </div>
                              {/* Three-dot menu icon with hover effect */}
                              <div className="absolute bottom-2 right-2">
                                  <button
                                      onClick={(e) => {
                                          e.stopPropagation(); // Prevent triggering folder card click
                                          toggleFolderMenu(folder.id);
                                      }}
                                      className="text-white p-2 px-3 rounded-full hover:bg-gray-700 focus:outline-none"
                                      style={{ transition: 'background-color 0.2s ease' }} // Smooth transition for hover effect
                                  >
                                      <i className="fas fa-ellipsis-v"></i>
                                  </button>
                              </div>
                              {/* Dropdown menu */}
                              {activeFolderMenu === folder.id && (
                                  <div className="absolute bottom-10 right-0 bg-white text-black shadow-lg rounded">
                                      <ul className="p-2">
                                          <li className="hover:bg-gray-200 p-2 cursor-pointer text-red-500 flex justify-between">
                                              <span onClick={(e) => handleMenuItemClick('move_to_trash', folder.id, 'folder', e)}>Move to trash</span>
                                          </li>
                                      </ul>
                                  </div>
                              )}
                          </div>
                      ))
                  )}
              </div>
          </>
      )}
  </div>
    );
}

export default FolderList;
