import React, { useRef, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer  } from 'recharts';
import { Pose } from '@mediapipe/pose';
import { drawLandmarks, drawConnectors } from '@mediapipe/drawing_utils';
import { useParams } from 'react-router-dom';
import '../index.css';
import '../assets/custom.css';
import { API_BASE_URL, BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';


import Header from '../components/Header';
import Footer from '../components/Footer';


import personRunningIcon from '../static/person-running-solid.svg';
import rightIcon from '../static/ar-right.svg';
import runIcon from '../static/run.png';
import leftIcon from '../static/ar-left.svg';


function VideoAnalysis() {
    const { videoId } = useParams();  // Get videoId from URL params
    const [data, setData] = useState([
        { name: 'MON', Steps: 54, 'All time average': 48 },
        { name: 'TUE', Steps: 41, 'All time average': 49 },
        { name: 'WED', Steps: 120, 'All time average': 88 },
        { name: 'THU', Steps: 98, 'All time average': 87 },
        { name: 'FRI', Steps: 24, 'All time average': 67 },
        { name: 'SAT', Steps: 34, 'All time average': 84 },
        { name: 'SUN', Steps: 140, 'All time average': 118 },
    ]);
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { name: 'Experiments', content: 'This is the content of tab 1' },
        { name: 'Exercise', content: 'This is the content of tab 2' },
    ];
    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    // State to handle accordion items
    const [accordionItems, setAccordionItems] = useState([
        { id: 1, isOpen: true, percentage: 100, name: "Head position", status: 'Great' },
        { id: 2, isOpen: false, percentage: 100, name: "Back position", status: 'Great' },
        { id: 3, isOpen: false, percentage: 70, name: "Foot Landing", status: 'Good' },
        { id: 4, isOpen: false, percentage: 70, name: "Ideal position", status: 'Good' },
        { id: 5, isOpen: false, percentage: 10, name: "Low heel kick", status: 'Bad' },
        { id: 6, isOpen: false, percentage: 10, name: "Overflexion", status: 'Bad' }
    ]);

    const [videoSrc, setVideoSrc] = useState(null); // State to hold video URL
    const [poseLandmarker, setPoseLandmarker] = useState(null);
    const [totalSessionTime, setTotalSessionTime] = useState(0);
    const [stepCount, setStepCount] = useState(0);
    const [isSteppingRight, setIsSteppingRight] = useState(false);
    const [isSteppingLeft, setIsSteppingLeft] = useState(false);
    const [maxStepLength, setMaxStepLength] = useState(0);
    const [stepLength, setStepLength] = useState(0);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(true);
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(true);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const knownHeightMeters = 1.75;

    useEffect(() => {
        // Fetch video details from backend
        const fetchVideoDetails = async () => {
            try {
                // Use the Axios instance to make the request
                const response = await axiosInstance.get(`${API_BASE_URL}/videos/video/${videoId}/`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    const videoData = response.data;
                    const fullVideoUrl = `${BASE_URL}${videoData.file}`; // Combine base URL with file path
                    setVideoSrc(fullVideoUrl); // Set video source URL
                } else {
                    console.error('Failed to fetch video data');
                }
            } catch (error) {
                console.error('Error fetching video data:', error);
            }
        };

        fetchVideoDetails();
    }, [videoId]);  // Dependency array includes videoId to refetch details when it changes


    useEffect(() => {
        const initializePoseLandmarker = async () => {
            const pose = new Pose({
                locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
            });

            pose.setOptions({
                modelComplexity: 1,
                smoothLandmarks: true,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5,
            });

            pose.onResults((results) => {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                if (context && results.poseLandmarks) {
                    context.clearRect(0, 0, canvas.width, canvas.height);

                    // Draw landmarks
                    drawLandmarks(context, results.poseLandmarks, {
                        radius: 2,
                        color: 'rgb(255, 255, 255)',
                    });

                    // Draw connectors
                    drawConnectors(context, results.poseLandmarks, Pose.POSE_CONNECTIONS, {
                        color: 'rgb(0, 255, 0)',
                        lineWidth: 4,
                    });

                    // Get landmarks for right ankle, right hip, and right knee
                    const rightAnkle = results.poseLandmarks[27];
                    const rightHip = results.poseLandmarks[23];
                    const rightKnee = results.poseLandmarks[25];
                    // Get landmarks for left ankle, left hip, and left knee
                    const leftAnkle = results.poseLandmarks[28];
                    const leftHip = results.poseLandmarks[24];
                    const leftKnee = results.poseLandmarks[26];

                    // Calculate angles for both legs
                    const rHiptoAnkle = getAngle(rightHip, rightKnee, rightAnkle);
                    const lHiptoAnkle = getAngle(leftHip, leftKnee, leftAnkle);

                    // Step counting logic based on angles
                    if (rHiptoAnkle > 160 && lHiptoAnkle <= 100 && !isSteppingRight) {
                        setStepCount(prevCount => prevCount + 1);
                        setIsSteppingRight(true);

                        // Save and display the step length in meters
                        const stepLengthPixels = Math.abs(rightAnkle.x - leftAnkle.x);
                        const metersPerPixel = knownHeightMeters / Math.abs(results.poseLandmarks[0].y - Math.min(rightAnkle.y, leftAnkle.y));
                        setStepLength((stepLengthPixels * metersPerPixel).toFixed(2));
                        setMaxStepLength(0); // Reset for the next step
                    } else if (lHiptoAnkle > 160 && rHiptoAnkle <= 100 && !isSteppingLeft) {
                        setStepCount(prevCount => prevCount + 1);
                        setIsSteppingLeft(true);

                        // Save and display the step length in meters
                        const stepLengthPixels = Math.abs(rightAnkle.x - leftAnkle.x);
                        const metersPerPixel = knownHeightMeters / Math.abs(results.poseLandmarks[0].y - Math.min(rightAnkle.y, leftAnkle.y));
                        setStepLength((stepLengthPixels * metersPerPixel).toFixed(2));
                        setMaxStepLength(0); // Reset for the next step
                    }

                    // Reset stepping state when legs are not extended
                    if (rHiptoAnkle < 160 && !isSteppingLeft) {
                        setIsSteppingRight(false);
                    }

                    if (lHiptoAnkle < 160 && !isSteppingRight) {
                        setIsSteppingLeft(false);
                    }

                    // Calculate and update the maximum step length in pixels
                    if (Math.abs(rightAnkle.x - leftAnkle.x) > maxStepLength) {
                        setMaxStepLength(Math.abs(rightAnkle.x - leftAnkle.x));
                    }

                    console.log('Pose Landmarks:', results.poseLandmarks);
                }
            });

            setPoseLandmarker(pose);
        };

        initializePoseLandmarker();
    }, []);

    useEffect(() => {
        if (poseLandmarker && videoRef.current) {
            const video = videoRef.current;

            const processVideo = async () => {
                if (video.readyState >= 2 && !video.paused && !video.ended) {
                    await poseLandmarker.send({ image: video });
                    setTotalSessionTime(prevTime => prevTime + 1);
                }
                requestAnimationFrame(processVideo);
            };

            video.addEventListener('play', processVideo);

            return () => {
                video.removeEventListener('play', processVideo);
            };
        }
    }, [poseLandmarker]);

    // Function to toggle the visibility of the left sidebar
    const toggleLeftSidebar = () => {
        setIsLeftSidebarVisible(!isLeftSidebarVisible);
    };

    // Function to toggle the visibility of the right sidebar
    const toggleRightSidebar = () => {
        setIsRightSidebarVisible(!isRightSidebarVisible);
    };

    // Toggle the accordion item
    const handleToggleAccordion = (id) => {
        setAccordionItems(
            accordionItems.map((item) => ({
                ...item,
                isOpen: item.id === id ? !item.isOpen : false
            }))
        );
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1300) {
                setIsLeftSidebarVisible(false);
                setIsRightSidebarVisible(false);
            } else {
                setIsLeftSidebarVisible(true);
                setIsRightSidebarVisible(true);
            }
        };

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header />
            <main>
                <div className="h-full">
                    <div className="flex px-4 mb-5">
                        <div className={`${isLeftSidebarVisible ? 'w-2/12' : 'w-0.5/12'}`}>
                            <div className="menu_item menu_left align-self-start col-2 order-1">
                                <div className="flex justify-between mb-5 pt-2.5">
                                    {isLeftSidebarVisible && (
                                        <div className="left text-white">All Metrics</div>
                                    )}
                                    <div className="title_right">
                                        <img
                                            className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${
                                                isLeftSidebarVisible ? '' : 'rotate-180'
                                            }`}
                                            src={`${BASE_URL}/${leftIcon}`}
                                            alt="left arrow icon"
                                            onClick={toggleLeftSidebar}
                                        />
                                    </div>
                                </div>
                                {isLeftSidebarVisible && (
                                    <div className="metrics">
                                        {/* Add your metrics content here */}
                                        <div className="metric">
                                            <div className="value">{`${Math.floor(totalSessionTime / 60)}:${('0' + (totalSessionTime % 60)).slice(-2)}`}</div>
                                            <div className="label">Total Session Time</div>
                                            <div className="description">Step frequency</div>
                                            <div className="icon_div">
                                                <img className="met_icon" src={`${BASE_URL}/${personRunningIcon}`} alt="person running icon" />
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <div className="value">{stepCount}</div>
                                            <div className="label">Steps</div>
                                            <div className="description">Step frequency</div>
                                            <div className="icon_div">
                                                <img className="met_icon" src={`${BASE_URL}/${personRunningIcon}`} alt="person running icon" />
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <div className="value">{stepLength} m</div>
                                            <div className="label">Step Length</div>
                                            <div className="description">Step frequency</div>
                                            <div className="icon_div">
                                                <img className="met_icon" src={`${BASE_URL}/${personRunningIcon}`} alt="person running icon" />
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <div className="value">0</div>
                                            <div className="label">Time of Flight</div>
                                            <div className="description">Step frequency</div>
                                            <div className="icon_div">
                                                <img className="met_icon" src={`${BASE_URL}/${personRunningIcon}`} alt="person running icon" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex-1 w-full'>
                            <div className="px-5 mt-5">
                                <div className="card1">
                                    <div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
                                        {videoSrc ? (
                                            <video
                                                ref={videoRef}
                                                controls
                                                src={videoSrc}
                                                className='w-auto h-[480px] max-w-full'
                                            >
                                                <source src={videoSrc} type="video/mp4" />
                                                Your browser does not support this video format.
                                            </video>
                                        ) : (
                                            <p>Loading video...</p>
                                        )}
                                        <canvas ref={canvasRef} width="800" height="480" />
                                    </div>
                                </div>
                                {/* Graphs */}
                                <div className="card mt-4">
                                    <div className="mb-5">
                                        <i className="fa fa-chart-line me-2"></i>
                                        <span>Graphs</span>
                                    </div>
                                    <div style={{ width: '100%', height: 300 }}>
                                        <ResponsiveContainer>
                                            <LineChart data={data}>
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <CartesianGrid stroke="#f5f5f5" />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="Steps" stroke="#8884d8" activeDot={{ r: 8 }} />
                                                <Line type="monotone" dataKey="All time average" stroke="#82ca9d" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                            </div>
                            </div>
                        </div>
                        <div className={`${isRightSidebarVisible ? 'w-3/12' : 'w-0.5/12'}`}>
                            <div className="menu_item menu_right">
                                <div className="flex justify-between mb-5 pt-2.5">
                                    <div className="left">
                                        <img
                                            className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${
                                                isRightSidebarVisible ? '' : 'rotate-180'
                                            }`}
                                            src={`${BASE_URL}/${rightIcon}`}
                                            alt="right arrow icon"
                                            onClick={toggleRightSidebar}
                                        />
                                    </div>
                                    {isRightSidebarVisible && (
                                        <div className="title_right text-white">Analysis</div>
                                    )}
                                </div>
                                <div className="accordion">
                                    {accordionItems.map((item, index) => (
                                        <div className="accordion-in mt-2" key={`accor_${index}`}>
                                            <div className="flex justify-between items-center bg-[#2D2D2D] p-4 mb-2 rounded-md">
                                                {/* Always show percentage indicator */}
                                                <div className="r-auto p-1 flex items-center">
                                                    <div className={`progress ${item.status === 'Bad' ? 'red' : ''}`}>
                                                        <span className="progress-left">
                                                            <span className={`progress-bar ${item.status === 'Bad' ? 'ten' : ''}`}></span>
                                                        </span>
                                                        <span className="progress-right">
                                                            <span className={`progress-bar ${item.status === 'Bad' ? 'ten' : ''}`}></span>
                                                        </span>
                                                        <div className="progress-value">{item.percentage}%</div>
                                                    </div>
                                                    {/* Make text closer to the percentage indicator */}
                                                    {isRightSidebarVisible && (
                                                        <div className="flex items-center ml-2">
                                                            <span className="text-white">{item.name}</span>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* Show name, status, and arrow only if right sidebar is visible */}
                                                {isRightSidebarVisible && (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="accordion-header flex items-center cursor-pointer" onClick={() => handleToggleAccordion(item.id)}>
                                                            <span className={`pBadge badge rounded-full ${item.status === 'Bad' ? 'bg-red' : 'bg-green'} w-16 h-6 flex items-center justify-center text-white`}>
                                                                {item.status}
                                                            </span>
                                                            <svg className={`w-5 h-5 ${item.isOpen ? 'rotate-180' : ''} ml-2`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {isRightSidebarVisible && item.isOpen && (
                                                <div className="p-4 accordion-body mt-2 rounded-md">
                                                    <div>
                                                        <img className="tab_head_img" src={`${BASE_URL}/${runIcon}`} alt="img" />
                                                    </div>
                                                    <div className="block p-5">
                                                        <ul className="acc_tab mt-2 nav nav-tabs flex" role="tablist">
                                                            {tabs.map((tab, index) => (
                                                                <li className="nav-item" key={`tab_${index}`}>
                                                                    <button
                                                                        onClick={() => handleTabChange(index)}
                                                                        className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                                                        type="button"  // Ensure it's treated as a button
                                                                    >
                                                                        {tab.name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>

                                                        <div className="tab-content">
                                                            <div className="fade tab-pane active show">
                                                                <div>
                                                                    <div className="mt-3">
                                                                        <h6 className="tab_heading">Current Position</h6>
                                                                        <p className="">Sub head</p>
                                                                        <p className="card-text">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fade tab-pane">
                                                                <div>This tab's content is never seen</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

// Function to calculate the angle between three points
function getAngle(start, middle, end) {
    const radians = Math.atan2(end.y - middle.y, end.x - middle.x) - Math.atan2(start.y - middle.y, start.x - middle.x);
    let angle = Math.abs(radians * 180.0 / Math.PI);
    if (angle > 180.0) {
        angle = 360.0 - angle;
    }
    return angle;
}

export default VideoAnalysis;
