import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FolderList from '../components/FolderList';
import FolderModal from '../components/FolderModal';
import Modal from '../components/Modal';
import Breadcrumb from '../components/Breadcrumb';
import { API_BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';

function Home() {
    const { folderId } = useParams(); // Get folderId from the URL parameters
    const navigate = useNavigate(); // To programmatically navigate
    const { setFolderId } = useContext(AppContext);
    const [folders, setFolders] = useState([]); // State to hold folder data
    const [videos, setVideos] = useState([]);  // State to hold videos data
    const [currentParent, setCurrentParent] = useState(folderId || null); // Initialize currentParent with URL parameter
    const [folderPath, setFolderPath] = useState([{ id: null, name: 'My Dashboard' }]);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false); // State for folder modal
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Ensure state is in sync with the URL
        if (folderId) {
            setCurrentParent(folderId);  // Set current folder from URL
            updateFolderPath(folderId);  // Update folder path for breadcrumb
        } else {
            // Navigate to root if folderId is not defined
            setCurrentParent(null);
            setFolderPath([{ id: null, name: 'My Dashboard' }]);
        }
    }, [folderId]); // Runs when URL folderId changes

    useEffect(() => {
        setFolderId(currentParent); // Set folder ID if needed

        // Function to fetch both folders and videos
        const fetchData = async () => {
          setLoading(true); // Start loading

          try {
            // Use Promise.all to fetch folders and videos simultaneously
            const [foldersResponse, videosResponse] = await Promise.all([
              fetchFolders(), // Fetch folders
              fetchVideos()   // Fetch videos
            ]);

            setFolders(foldersResponse); // Set folders data
            setVideos(videosResponse);   // Set videos data
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false); // End loading
          }
        };

        fetchData(); // Call the function to fetch data
      }, [currentParent]); // Dependency array

    const fetchFolders = async () => {
        try {
            const url = currentParent === null
            ? `${API_BASE_URL}/folders/parent/`
            : `${API_BASE_URL}/folders/parent/${currentParent}/`;

            const response = await axiosInstance.get(url);
            return response.data; // Return folders data instead of setting state
        } catch (error) {
            console.error('Error fetching folders:', error);
            return []; // Return empty array on error to prevent breaking Promise.all
        }
    };

    const fetchVideos = async () => {
        try {
            const url = currentParent === null
            ? `${API_BASE_URL}/videos/folder/root/`
            : `${API_BASE_URL}/videos/folder/${currentParent}/`;

            const response = await axiosInstance.get(url);
            return response.data; // Return videos data instead of setting state
        } catch (error) {
            console.error('Error fetching videos:', error);
            return []; // Return empty array on error to prevent breaking Promise.all
    }
    };



    const handleCreateFolder = async (folderData) => {
        try {
            // Make the API request using the axios instance
            const response = await axiosInstance.post(`${API_BASE_URL}/folders/create/`, {
                ...folderData,
                parent: currentParent
            });

            // Check if the response is okay and handle success
            if (response.status === 201) {
                const newFolder = response.data; // Get the newly created folder
                setFolders((prevFolders) => [...prevFolders, newFolder]); // Update state manually
                setIsFolderModalOpen(false); // Close the modal
            } else {
                throw new Error('Failed to create folder');
            }
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const handleViewFolder = (folderId) => {
        setCurrentParent(folderId);
        if (folderId !== null) {
            updateFolderPath(folderId);
            navigate(`/folder/${folderId}`);
        } else {
            setFolderPath([{ id: null, name: 'My Dashboard' }]);
            navigate('/home');
        }
    };

    const handleNavigateBreadcrumb = (folderId) => {
        setCurrentParent(folderId);
        if (folderId === null) {
            setFolderPath([{ id: null, name: 'My Dashboard' }]);
            navigate('/home');
        } else {
            updateFolderPath(folderId); // Update breadcrumb path
            navigate(`/folder/${folderId}`);
        }
    };

    const updateFolderPath = (folderId) => {
        // Clear the current breadcrumb path to reconstruct it
        const path = [{ id: null, name: 'My Dashboard' }];
        let currentFolderId = folderId;

        while (currentFolderId) {
            const folder = folders.find(f => f.id === currentFolderId);
            if (folder) {
                path.unshift({ id: folder.id, name: folder.name }); // Prepend to path
                currentFolderId = folder.parent; // Use correct parent reference
            } else {
                break;
            }
        }

        setFolderPath(path);
    };

    return (
        <div>
            <Header
                onCreateFolder={() => setIsFolderModalOpen(true)} // Open create folder modal
            />
            <main>
                <div className="h-full px-4 py-6">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            {/* Loading Spinner */}
                            <svg
                                className="animate-spin h-8 w-8 text-[#4caf50]"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8v8H4z"
                                ></path>
                            </svg>
                        </div>
                    ) : (
                        <>
                            {/* Render Breadcrumb and FolderList when not loading */}
                            <Breadcrumb folderPath={folderPath} onNavigate={handleNavigateBreadcrumb} />
                            <FolderList
                                folders={folders}
                                videos={videos}
                                onViewFolder={handleViewFolder}
                                setFolders={setFolders}   // Pass setFolders as a prop
                                setVideos={setVideos}
                            />
                        </>
                    )}
                </div>
            </main>
            <Footer />

            <Modal isOpen={isFolderModalOpen} onClose={() => setIsFolderModalOpen(false)}>
                <FolderModal onCreate={handleCreateFolder} onClose={() => setIsFolderModalOpen(false)} />
            </Modal>
        </div>

    );
}

export default Home;
