import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../AppContext';

const PublicRoute = ({ children }) => {
  const { user } = useContext(AppContext);

  if (user) {
    // If user is logged in, redirect to home page
    return <Navigate to="/home" />;
  }

  // If user is not logged in, allow access to the route
  return children;
};

export default PublicRoute;
