import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import { AppContext } from '../AppContext';
import axios from 'axios'; // Make sure to import Axios at the top of your file


function Login() {
  const { login } = useContext(AppContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true); // Start loading

  try {
    const response = await axios.post(`${BASE_URL}/api/auth/login/`, {
      username,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const data = response.data;

      // Save refresh and access tokens in local storage
      login(data.tokens, data.user);

      navigate('/'); // Redirect to the home page
    } else {
      setErrorMessage(response.data.error || 'Login failed');
    }
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      setErrorMessage(error.response.data.error || 'Login failed');
    } else {
      setErrorMessage('Error logging in');
    }
    console.error('Error logging in', error);
  } finally {
    setLoading(false); // Stop loading
  }
};

  return (
	<div className="flex items-center justify-center h-screen bg-gray-100">
	<div className="w-full max-w-md bg-white p-8 rounded shadow-md">
	  <h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Login</h2>
	  {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
	  <form onSubmit={handleSubmit} className="space-y-4">
		<input
		  type="text"
		  placeholder="Username"
		  value={username}
		  onChange={(e) => setUsername(e.target.value)}
		  autoFocus
		  required
		  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] text-[#212121]"
		/>
		<input
		  type="password"
		  placeholder="Password"
		  value={password}
		  onChange={(e) => setPassword(e.target.value)}
		  required
		  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-[#4caf50] text-[#212121]"
		/>
		<div className="text-right" style={{ margin: 0 }}>
		  <Link to="/forgot-password" className="text-sm text-blue-500 hover:underline">
			Forgot Password?
		  </Link>
		</div>
		<button
		  type="submit"
		  className={`w-full flex items-center justify-center bg-[#4caf50] text-white py-2 rounded hover:bg-green-600 focus:outline-none ${
			loading ? 'cursor-not-allowed' : ''
		  }`}
		  disabled={loading}
		>
		  {loading ? (
			<svg
			  className="animate-spin h-5 w-5 text-white"
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			>
			  <circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			  ></circle>
			  <path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8v8H4z"
			  ></path>
			</svg>
		  ) : (
			'Login'
		  )}
		</button>
	  </form>
	  <p className="text-center mt-4">
		Create an account?{' '}
		<Link to="/register" className="text-blue-500 hover:underline">
		  Register
		</Link>
	  </p>

	  <div className="text-center mt-4">
		<div className="relative mb-4">
		  <div className="absolute inset-0 flex items-center">
			<div className="w-full border-t border-gray-300"></div>
		  </div>
		  <div className="relative flex justify-center text-sm">
			<span className="bg-white px-2 text-gray-500">OR</span>
		  </div>
		</div>
		<button className="w-full flex items-center justify-center py-2 mb-2 border border-gray-300 rounded-md hover:bg-gray-50">
		  <img
			className="h-5 w-5 mr-2"
			src={require('../static/google.jpeg')}
			alt="Google icon"
		  />
		  Continue with Google
		</button>
	  </div>
	</div>
  </div>

  );
}

export default Login;
