import React from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom';
import Home from './screens/Home';
import VideoAnalysis from './screens/VideoAnalysis';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import Login from './screens/Login';
import Register from './screens/Register';
import VerifyEmail from './screens/VerifyEmail';

import VideoUpload from './screens/VideoUpload';
import PublicRoute from './components/PublicRoute';
import ProtectedRoute from './components/ProtectedRoute';
import './index.css';
import './assets/custom.css';

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                {/* Public Routes */}
                <Route
                    path="/login"
                    element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                    <PublicRoute>
                        <Register />
                    </PublicRoute>
                    }
                />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />

                {/* Protected Routes */}
                <Route
                    path="/home"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/folder/:folderId"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-upload"
                    element={
                    <ProtectedRoute>
                        <VideoUpload />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-analysis/:videoId"
                    element={
                    <ProtectedRoute>
                        <VideoAnalysis />
                    </ProtectedRoute>
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
